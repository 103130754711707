import React from 'react';
import { Card } from 'react-bootstrap';

const CosmosCard = ({ title, amount }) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{amount}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CosmosCard;
