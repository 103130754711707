import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from 'react-bootstrap';
import CosmosCard from './components/CosmosCard';

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/val-uptime')
      .then(response => response.json())
      .then(data => {
        setData(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data: ', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col xs={12} md={6}>
          <h1 className="text-center mb-4">Cosmos Stats</h1>
          {data.map((item, index) => (
            <CosmosCard key={index} title={Object.keys(item)[0]} amount={Object.values(item)[0]} />
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default App;

